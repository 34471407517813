<template>
    <div>
        <Loading  v-if="!requested" />
        <div v-else>
            <span class="border__divider"></span>
            <div class="merchants">
                <div class="merchants-wrapper">
                    <div class="merchants-main">
                        <div class="merchants-header">
                            <a href="/">Home</a>
                            <v-icon color="black" size="24">
                                {{icons.mdiChevronRight}}
                            </v-icon>
                            <span>New Merchants</span>
                        </div>
                        <div class="merchants-category">

                            <v-card v-for="store in stores" :key="store.id" class="merchant-card-main" flat rounded="0">
                                <div>
                                    <v-card width="90" rounded="lg">
                                        <v-img :src="store.thumbnail" height="90"
                                        ></v-img>
                                    </v-card>
                                </div>
                                <div class="merchant-text">
                                    <div class="merchant-text-title">
                                        <h2>{{store.name}}</h2>
                                    </div>
                                    <div class="merchant-text-desc">
                                        <span>{{store.address}}, {{store.city}}</span>
                                        <span>Delivery, Pick up</span>
                                    </div>
                                </div>
                                
                                
                            </v-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from "./Loading.vue"
import { mdiChevronRight } from '@mdi/js';
import { getStoreList } from '@/components/api/api.js'

export default {
name: "Merchants",
components: {
    Loading,
},
data() {
    return {
        icons: {
            mdiChevronRight
        },
        requested: false,
        stores:[],
    }
},
created() {
    this.getData()
},
methods: {
    getData(){
        getStoreList()
            .then(res => {
                const indexpage = res.data
                this.stores = indexpage
                this.requested =true
            }).catch(error => {
                console.log('error :', error)
            })
        },
},

}
</script>

<style lang='scss'>
@import "../components/common/css/global.scss";
@import "../components/common/css/common.scss";


.border__divider{
    height: 8px;
    background: $white5;
    width: 100%;
    display: block;
}

.merchants {
    .merchants-wrapper{
        padding: 0 14px;
    }
    .merchants-main{
        padding: 20px 0;
    }
    .merchants-header{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        
        a{
            text-decoration: none;
            font-size: 24px;
            font-family: Arial, Helvetica, sans-serif;
            @media screen and (max-width: $bp-mobile){
                font-size: 20px;
            }
        }
        span{
            font-size: 24px;
            font-family: Arial, Helvetica, sans-serif;
            @media screen and (max-width: $bp-mobile){
                font-size: 20px;
            }
        }
    }

    .merchants-category{
        .merchant-card-main{
            display: flex;
            align-items: center;
            padding: 10px 0;
            margin: 0 0 5px;
            border-bottom: 1px solid $white3;
            
        }
        .merchant-text{
            margin-left: 15px;
            width: 100%;
            height: 96px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 5px;
                h2{
                display: block;
                line-height: 20px;
                height: 42px;
                overflow: hidden;
                font-size: 18px;
                font-weight: 600;
                color: $gray1;
            }
            .merchant-text-desc{
                margin: 5px 0;
                display: flex;
                flex-direction: column;
                span:nth-child(1){
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                span{
                    overflow: hidden;
                    display: block;
                    height: 20px;
                    font-weight: 400;
                    color: $gray2;
                    font-size: 12px;
                    font-family: Arial, Helvetica, sans-serif;
                }
            }
            
        }
    }
}

</style>